import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const isBrowser = typeof window !== 'undefined';

const config = {
  apiKey: "AIzaSyCvOHAVUmEj6-IUpjtogq3tK3s4Cv8xtTQ",
  authDomain: "able-home-care-llc.firebaseapp.com",
  databaseURL: "https://able-home-care-llc.firebaseio.com",
  projectId: "able-home-care-llc",
  storageBucket: "able-home-care-llc.appspot.com",
  messagingSenderId: "562510843225"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.firestore = app.firestore();
    this.storage = app.storage();
  }

  signInWithCustomToken = token => this.auth.signInWithCustomToken(token);
  signOut = () => this.auth.signOut();
  collection = query => this.firestore.collection(query);
  ref = () => this.storage.ref();
  batch = () => this.firestore.batch();
}

export default !app.apps.length
  ? isBrowser ? new Firebase() : ""
  : app.app()
