/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "gatsby";
//auth
import { Logout } from 'Auth/AuthService';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Notifications from "@material-ui/icons/Notifications";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes, dropdownHoverColor } = props;

  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <Link to="/" style={{ color: "#fff"}}>
          <Button
            color="transparent"
            className={classes.navLink}
          >
            Home
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/course-list" style={{ color: "#fff"}}>
          <Button
            color="transparent"
            className={classes.navLink}
          >
            Courses
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          className={classes.navLink}
          onClick={() => Logout()}
          color="transparent"
        >
          Logout
        </Button>
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

/*

<ListItem className={classes.listItem}>
  <CustomDropdown
    right
    caret={true}
    hoverColor="primary"
    dropdownHeader="Notifications"
    buttonText={
      <Notifications />
    }
    buttonProps={{
      className:
      classes.navLink + " " + classes.imageDropdownButton,
      color: "transparent"
    }}
    dropdownList={[
      "currently",
      "disabled",
      "Sign out"
    ]}
  />
</ListItem>

*/


/*

<ListItem className={classes.listItem}>
  <CustomDropdown
    left
    caret={false}
    hoverColor="dark"
    dropdownHeader="Dropdown Header"
    buttonText={
      <UserAvatar
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          alignItems: "center"
        }}
        size="48"
        name="Edward Beazer"
        src="https://firebasestorage.googleapis.com/v0/b/able-home-care-llc.appspot.com/o/avatars%2Febeazer%40ablehomecarellc.com.jpg?alt=media&token=43caef57-4b03-4355-a192-da21fddccef3"
      />
    }
    buttonProps={{
      className:
      classes.navLink + " " + classes.imageDropdownButton,
      color: "transparent"
    }}
    dropdownList={[
      "currently",
      "disabled",
      "Sign out"
    ]}
  />
</ListItem>
*/
HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};

export default withStyles(headerLinksStyle)(HeaderLinks);
