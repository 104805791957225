/* eslint-disable */
import auth0js from 'auth0-js';
import { navigate } from 'gatsby';
// external packages
import Firebase from "../firebase";
const isBrowser = typeof window !== 'undefined';

// To speed things up, we’ll keep the profile stored unless the user logs out.
// This prevents a flicker while the HTTP round-trip completes.
let profile = false;

let tokenRenewalTimeout;

// Only instantiate Auth0 if we’re in the browser.
const auth0 = isBrowser
  ? new auth0js.WebAuth({
    domain: "ablehomecare.auth0.com",
    redirectUri: `${process.env.Auth_Callback}`,
    clientID: "Tz3inSJ74R2OcfUyiPlZIkl6Bb4yqSOP",
    audience: "http://api.ablehomecarellc.com",
    responseType: 'token id_token',
    scope: 'openid profile email',
  })
  : {};

export const Login = () => {
  if (!isBrowser) {
    return;
  }
  auth0.authorize();
};

export const Logout = () => {
  if (isBrowser) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user');
    localStorage.removeItem('callbackLink');
  }
  Firebase.auth.signOut();
  // Remove the locally cached profile to avoid confusing errors.
  profile = false;
  navigate('/login');
};

const setSession = (authResult) => {
  if (!isBrowser) {
    return;
  }

  const expiresAt = JSON.stringify(
    authResult.expiresIn * 1000 + new Date().getTime(),
  );

  localStorage.setItem('access_token', authResult.accessToken);
  localStorage.setItem('id_token', authResult.idToken);
  localStorage.setItem('expires_at', expiresAt);
  auth0.client.userInfo(authResult.accessToken, (err, user) => {
    localStorage.setItem("user", JSON.stringify(user));
  });

  return true;
};

const renewToken = () => {
  this.auth0.checkSession({}, (err, result) => {
    if (err) {
      return;
    } else {
      setSession(result);
    }
  });
}

const scheduleRenewal = () => {
  const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
  const delay = expiresAt - Date.now();
  if (delay > 0) {
    this.tokenRenewalTimeout = setTimeout(() => {
      renewToken();
    }, delay);
  }
}

export const handleAuthentication = () => {
  if (!isBrowser) {
    return;
  }

  auth0.parseHash((err, authResult) => {
    if (authResult && authResult.accessToken && authResult.idToken) {
      setSession(authResult);
    } else if (err) {
      console.error(err);
    }
  });
};

export const isAuthenticated = () => {
  if (!isBrowser) {
    // For SSR, we’re never authenticated.
    return false;
  }

  const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
  return new Date().getTime() < expiresAt;
};

export const getAccessToken = () => {
  if (!isBrowser) {
    return '';
  }
  return localStorage.getItem('access_token');
};

export const getCallbackLink = () => {
  if (!isBrowser) {
    return '';
  }
  return localStorage.getItem('callbackLink');
};

export const setCallbackLink = (link) => {
  if (!isBrowser) {
    return '';
  }
  localStorage.setItem('callbackLink', link);
};

const getUser = () => {
  if (isBrowser) {
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user"));
    }
  }
}

export const getUserEmail = () => {
  if (getUser()) {
    return getUser().email;
  }
}
